import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useBasket, useConfig } from 'msp-integrations'
import { AlertBox, Modal, Events, EventsEmitter, Icons } from 'msp-components'
import BonusProductLine from './BonusProductLine'
import { twMerge } from 'tailwind-merge'

const ChoiceOfBonusProductsModal = () => {
  const { formatMessage } = useIntl()
  const {
    theme: { font, fontVariant }
  } = useConfig()
  const basket = useBasket()
  const bonusProductsEmitter = useRef(EventsEmitter)
  const alertBoxRef = useRef<AlertBox>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [bonusDiscountLineItem, setBonusDiscountLineItem] = useState(null)
  const [selectedBonusProducts, setSelectedBonusProducts] = useState(
    new Map<string, any>()
  )
  const [alertData, setAlertData] = useState({ type: '', title: '' })

  const modalHeader = (
    <div
      className={`${fontVariant.bold} text-24 leading-32 ${
        bonusDiscountLineItem?.c_welcomeOfferCampaign ? 'mb-2' : 'mb-5'
      }`}
    >
      {formatMessage(
        {
          defaultMessage: 'Select {maxBonusItems} free bonus item(s)',
          id: 'msp.choice_of_bonus_products_modal.title'
        },
        { maxBonusItems: bonusDiscountLineItem?.maxBonusItems || 0 }
      )}
    </div>
  )

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const closeButtonClick = () => {
    closeModal()
  }

  const showAlert = (alertData: any) => {
    setAlertData(alertData)
    alertBoxRef?.current?.showAlert()
  }

  useEffect(() => {
    if (basket?.productItems?.length > 0) {
      let foundBonusProducts = false
      const updatedBonusProducts = new Map<string, any>(selectedBonusProducts)

      basket?.productItems.forEach((productItem) => {
        if (
          productItem.bonusProductLineItem &&
          productItem.bonusDiscountLineItemId === bonusDiscountLineItem?.id
        ) {
          foundBonusProducts = true
          updatedBonusProducts.set(productItem.productId, productItem)
        }
      })

      selectedBonusProducts.forEach((_, productId) => {
        const isProductInBasket = basket.productItems.some(
          (productItem) => productItem.productId === productId
        )

        if (!isProductInBasket) {
          updatedBonusProducts.delete(productId)
        }
      })

      setSelectedBonusProducts(updatedBonusProducts)

      if (!foundBonusProducts) {
        setSelectedBonusProducts(new Map<string, any>())
      }
    }
  }, [isModalOpen, basket?.productItems])

  useEffect(() => {
    if (
      !bonusProductsEmitter.current?.has(
        Events.OPEN_CHOICE_OF_BONUS_PRODUCTS_MODAL
      )
    ) {
      bonusProductsEmitter.current?.subscribe(
        Events.OPEN_CHOICE_OF_BONUS_PRODUCTS_MODAL,
        (bonusDiscountLineItem: any) => {
          if (!isModalOpen) {
            setBonusDiscountLineItem(bonusDiscountLineItem)
            setIsModalOpen(true)
          }
        }
      )
    }

    return () => {
      bonusProductsEmitter.current?.unsubscribe(
        Events.OPEN_CHOICE_OF_BONUS_PRODUCTS_MODAL
      )
    }
  }, [isModalOpen, bonusDiscountLineItem])

  return (
    <>
      <AlertBox
        ref={alertBoxRef}
        type={alertData.type}
        title={alertData.title}
        autoCloseTime={2500}
        closeButtonIcon={<Icons iconName='Close' size='base' />}
      />
      <Modal
        isExtended
        style='w-full md:w-11/12 lg:w-3/4 2xl:1/2 xl:max-w-screen-2xl max-h-[90vh] md:max-h-[80vh]'
        isOpen={isModalOpen}
        onClose={closeModal}
        onCloseButtonClick={closeButtonClick}
        overlay
        closeOnOverlayClick
        blockScroll
        modalHeader={modalHeader}
        modalBody={
          <>
            {bonusDiscountLineItem?.c_welcomeOfferCampaign && (
              <p className={twMerge(font.regular, 'mb-5')}>
                {formatMessage({
                  defaultMessage:
                    'Your welcome offer includes a free product of your choice. Select your free product by clicking the cart button.',
                  id: 'msp.choice_of_bonus_products_modal.welcome_offer_title'
                })}
              </p>
            )}
            <div className='-mx-10 flex flex-col overflow-auto md:m-0'>
              {bonusDiscountLineItem?.bonusProducts &&
                bonusDiscountLineItem?.bonusProducts.map((bonusProduct) => (
                  <div key={`bonus-product-tile-${bonusProduct.productId}`}>
                    <BonusProductLine
                      productId={bonusProduct.productId}
                      quantity={
                        selectedBonusProducts.has(bonusProduct.productId)
                          ? selectedBonusProducts.get(bonusProduct.productId)
                              .quantity
                          : 1
                      }
                      bonusDiscountLineItemId={bonusDiscountLineItem?.id}
                      basket={basket}
                      selectedBonusProducts={selectedBonusProducts}
                      maxBonusItems={bonusDiscountLineItem?.maxBonusItems || 0}
                      closeModal={closeModal}
                      showAlert={showAlert}
                    />
                  </div>
                ))}
            </div>
          </>
        }
      />
    </>
  )
}

export default ChoiceOfBonusProductsModal
